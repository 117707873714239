.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  cursor: pointer;
}

.App-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  /* font-size: calc(10px + 2vmin); */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: lightgray;
  -webkit-box-shadow: 0 0 0px 1000px var(--neutralTertiary) inset;
  box-shadow: 0 0 0px 1000px var(--neutralTertiary) inset;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--neutralTertiary);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--themePrimary);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

div {
  color: var(--bodyText);
}

.zoombar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.zoombar.vertical {
  flex-direction: column;
}

.zoom-button-top,
.zoom-button-bottom {
  width: 2rem;
  height: 2rem;
  background-color: var(--neutralDark);
}

.zoom-button-top:hover,
.zoom-button-bottom:hover {
  background-color: var(--neutralDark);
}

.zoom-button-top .ms-Button-icon,
.zoom-button-bottom .ms-Button-icon {
  font-size: 1.2rem;
  color: var(--neutralQuaternary);
}

.zoom-button-top:hover .ms-Button-icon,
.zoom-button-bottom:hover .ms-Button-icon {
  color: var(--themePrimary);
}

.zoom-button-top {
  border-radius: 0.5rem 0.5em 0 0;
  padding: 0;
  margin: 0;
}

.zoom-button-bottom {
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 0;
  margin: 0;
  margin-top: 1rem;
}

@media (max-width: 500px) {
  .zoom-button-top,
  .zoom-button-bottom {
    width: 1.5rem;
    height: 1.5rem;
  }

  .zoom-button-top .ms-Button-icon,
  .zoom-button-bottom .ms-Button-icon {
    font-size: 1rem;
  }

  .zoom-button-top {
    border-radius: 0.3rem 0 0 0.3rem;
  }

  .zoom-button-bottom .ms-Button-flexContainer {
    border-radius: 0 0.3rem 0.3rem 0;
  }
}

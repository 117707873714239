.header {
  pointer-events: all;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: var(--neutralDark);
  height: var(--navHeaderHeight);
  overflow: hidden;
}

.header-left {
  height: 100%;
  padding-top: 0.25em;
  padding-left: 2em;
  overflow: hidden;
  vertical-align: middle;
  user-select: none;
}

.header-left > span:nth-child(1) {
  font-size: 1.5em;
  font-weight: 300;
  text-transform: uppercase;
  margin-right: 0.2em;
  color: var(--neutralTertiaryAlt);
}

.header-left > span:nth-child(2) {
  font-size: 1.5em;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 0.2em;
  color: #fff;
}

.header-left > span:nth-child(3) {
  font-size: 1em;
  font-weight: 300;
  color: var(--neutralTertiaryAlt);
}

.header-right {
  display: flex;
  flex: 1 0.5;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1em;
}
